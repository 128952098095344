import {
  faFacebookSquare,
  faInstagramSquare,
  faTwitterSquare,
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export type ShareProps = {
  padding: number
  fontSize: number
  color: string
}

export const SnsShare = (props: ShareProps) => {
  const iconStyle: React.CSSProperties = {
    padding: props.padding,
    fontSize: props.fontSize,
    color: props.color,
  }
  return (
    <div>
      <a href="https://www.instagram.com/yachinaka_farm/">
        <FontAwesomeIcon style={iconStyle} icon={faInstagramSquare} />
      </a>
      <a href="https://twitter.com/yachinaka_farm">
        <FontAwesomeIcon style={iconStyle} icon={faTwitterSquare} />
      </a>
      <a href="https://www.facebook.com/%E3%82%84%E3%81%A1%E3%81%AA%E3%81%8B%E3%83%95%E3%82%A1%E3%83%BC%E3%83%A0-107138414392234/">
        <FontAwesomeIcon style={iconStyle} icon={faFacebookSquare} />
      </a>
    </div>
  )
}
