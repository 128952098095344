import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { createMuiTheme } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'

import React from 'react'
import Home from 'pages/Home'
import { Footer } from 'components/Footer'
import Header from 'components/Header'
import { Privacy } from 'pages/Privacy'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#F10207',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 30,
      },
    },
  },
  typography: {
    h1: {
      fontSize: '3vmax',
      fontWeight: 'bold',
    },
    h2: {
      fontSize: '3vmax',
    },
    h3: {
      fontSize: '15px',
      fontWeight: 'bold',
    },
    h4: {
      fontSize: '0.75rem',
      lineHeight: '25px',
    },
    h5: {
      fontSize: '10px',
      lineHeight: '20px',
    },
  },
})

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <BrowserRouter>
          <Header />
          <body>
            <Switch>
              <Route exact path="/privacy" component={Privacy} />
              <Route exact path="/" component={Home} />
            </Switch>
          </body>
          <Footer />
        </BrowserRouter>
      </div>
    </ThemeProvider>
  )
}
export default App
