import {
  AppBar,
  Box,
  Button,
  createStyles,
  Dialog,
  IconButton,
  makeStyles,
  Slide,
  Theme,
  Toolbar,
  Typography,
  useScrollTrigger,
  useTheme,
} from '@material-ui/core'
import React, { useState } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import MenuIcon from '@material-ui/icons/Menu'
import { SnsShare } from './SnsShare'
import { localImage } from 'utils/utils'
import { Link } from 'react-router-dom'

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window
  children: React.ReactElement
}

function HideOnScroll(props: Props) {
  const { children, window } = props
  const trigger = useScrollTrigger({ target: window ? window() : undefined })

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      flexGrow: 1,
      align: 'center',
    },
    relative_box: {
      position: 'relative',
    },
    close_button: {
      display: 'flex',
      position: 'absolute',
      top: '0',
      left: '0',
      marginLeft: '2vh',
      marginTop: '1vh',
    },
    menu_contents: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    menu_image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    [theme.breakpoints.down('xs')]: {
      icons: {
        display: 'none',
      },
      logo: {
        marginLeft: '-3rem',
      },
      menu_contents: {
        width: '60%',
      },
      menu_logo: {
        width: '15vw',
        height: '15vw',
      },
    },
    [theme.breakpoints.up('sm')]: {
      icons: {
        display: 'inline',
      },
      logo: {
        marginLeft: '5rem',
      },
      menu_contents: {
        width: '50%',
      },
      menu_logo: {
        width: '15vw',
        height: '15vw',
      },
    },
    [theme.breakpoints.up('md')]: {
      icons: {
        display: 'inline',
      },
      logo: {
        marginLeft: '5rem',
      },
      menu_contents: {
        width: '30%',
      },
      menu_logo: {
        width: '7.5vw',
        height: '7.5vw',
      },
    },
  }),
)

const Header = () => {
  const classes = useStyles()
  const theme = useTheme()
  const [open, setOpen] = useState(Boolean)
  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  return (
    <header>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css"
      />
      <Dialog fullScreen open={open} onClose={handleClose}>
        <img
          className={classes.menu_image}
          src={localImage('background01.jpg')}
          alt="background"
        />
        <MenuContents />
        <IconButton
          aria-label="Close"
          onClick={() => handleClose()}
          className={classes.close_button}
          style={{ backgroundColor: 'white' }}
        >
          <CloseIcon />
        </IconButton>
      </Dialog>
      <HideOnScroll>
        <AppBar color="inherit">
          <Toolbar>
            <IconButton aria-label="Menu" onClick={() => handleOpen()}>
              <MenuIcon />
            </IconButton>
            <div className={classes.title} style={{ textAlign: 'center' }}>
              <Link to="/" className={classes.logo}>
                <img
                  // className={classes.title}
                  width="45"
                  height="45"
                  src={process.env.PUBLIC_URL + '/res/logo01.svg'}
                  alt="logo"
                />
              </Link>
            </div>
            <Box flex flexDirection="row" className={classes.icons}>
              {/* シェアアイコン */}
              <SnsShare
                padding={10}
                fontSize={25}
                color={theme.palette.primary.main}
              />
            </Box>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </header>
  )
}

const MenuContents = () => {
  const classes = useStyles()
  const theme = useTheme()
  return (
    <Box
      padding={4}
      borderRadius={16}
      bgcolor="white"
      className={classes.menu_contents}
      flexDirection="column"
      width="30vw"
    >
      <img
        className={classes.menu_logo}
        src={localImage('logo01.svg')}
        alt="background"
      />
      <Box mt={4} />
      {/* <Box flexDirection="row" display="flex" justifyContent="center">
        <Box>
          <Typography variant="h3">やちなかファームとは</Typography>
        </Box>
        <Box borderLeft={1} borderColor="grey.300" mx={2} />
        <Box>
          <Typography variant="h3">Dr.トマト</Typography>
        </Box>
      </Box> */}
      <Box mt={4} />
      <Button
        variant="contained"
        color="primary"
        className={'Primary-Yachinaka-Button'}
        href="https://yachinaka.theshop.jp/"
      >
        <Typography variant="h3">購入はこちら</Typography>
      </Button>
      <Box mt={8} />
      <Typography variant="h4">
        購入以外に関することはこちらからお願いします。
      </Typography>
      <Box mt={2} />
      <Button
        variant="contained"
        color="primary"
        className={'Primary-Yachinaka-Button'}
        href="mailto:yachinakafarm@gmail.com?subject=お問い合わせ"
      >
        <Typography variant="h3">お問い合わせ</Typography>
      </Button>
      <Box mt={8} />
      <Typography variant="h4">やちなかファームのSNS</Typography>
      <SnsShare padding={10} fontSize={30} color={theme.palette.primary.main} />
    </Box>
  )
}

export default Header
