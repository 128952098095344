import {
  makeStyles,
  createStyles,
  Grid,
  Typography,
  Box,
} from '@material-ui/core'
import React from 'react'
import { SnsShare } from './SnsShare'

const useStyles = makeStyles(() =>
  createStyles({
    footer: {
      backgroundColor: '#F8F8F8',
      paddingTop: '20px',
      paddingBottom: '30px',
    },
    footer_inner: {
      paddingLeft: '10%',
      paddingRight: '10%',
    },
    link_area: {
      textAlign: 'start',
    },
    link: {
      marginLeft: '40px',
      listStyleType: 'none',
    },
    link_box: {
      paddingTop: '20px',
      paddingBottom: '20px',
      paddingLeft: '40px',
    },
    links_item: {
      textDecoration: 'none',
      color: '#333333',
    },
  }),
)

export const Footer = () => {
  const classes = useStyles()
  return (
    <footer className={classes.footer}>
      <Box mt={4} />
      <Grid container className={classes.footer_inner}>
        <Grid item container xs={12} md={6}>
          <Grid item>
            <Typography variant="h4">
              〒038-3133 青森県つがる市木造末広19-1
            </Typography>
            <Box mt={1} />
            <Typography variant="h5">青森県弘前市</Typography>
            <Typography variant="h5">Email: yachinakafarm@gmail.com</Typography>
          </Grid>
          <Grid item container justify="flex-end" alignItems="flex-end">
            <Box marginRight="30px" marginTop="-30px">
              {/* シェアアイコン */}
              <SnsShare padding={5} fontSize={20} color={'#333333'} />
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} className={classes.link_area}>
          <Box
            borderLeft={1}
            className={classes.link_box}
            borderColor="grey.300"
          >
            <Typography variant="h4">購入先一覧</Typography>
            <Box
              display="flex"
              flexDirection="row"
              flexWrap="noWrap"
              mt={1}
              ml={-1}
            >
              <Box px={1}>
                <a
                  className={classes.links_item}
                  href="https://fril.jp/shop/af955c2976e0171bd4f9e9103ff55b98"
                >
                  <Typography variant="h5">楽天ラクマから購入</Typography>
                </a>
              </Box>
              <Box px={1}>
                <a
                  className={classes.links_item}
                  href="https://www.mercari.com/jp/u/519216848/"
                >
                  <Typography variant="h5">メルカリから購入</Typography>
                </a>
              </Box>
              <Box px={1}>
                <a
                  className={classes.links_item}
                  href="https://poke-m.com/producers/215041"
                >
                  <Typography variant="h5">ポケットマルシェから購入</Typography>
                </a>
              </Box>
              <Box px={1}>
                <a
                  className={classes.links_item}
                  href="https://yachinaka.theshop.jp/"
                >
                  <Typography variant="h5">ショップサイトから購入</Typography>
                </a>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box borderTop={1} borderColor="grey.300" marginTop={4} />
      <Box mb={4} />
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="noWrap"
        mt={1}
        ml={-1}
        justifyContent="center"
      >
        <Box px={1}>
          <a
            className={classes.links_item}
            href="mailto:yachinakafarm@gmail.com?subject=お問い合わせ"
          >
            <Typography variant="h5">お問い合わせ</Typography>
          </a>
        </Box>
        {/* <Typography variant="h5">　/　</Typography>
        <Box px={1}>
          <Link className={classes.links_item} to="/privacy">
            <Typography variant="h5">プライバシーポリシー</Typography>
          </Link>
        </Box> */}
      </Box>
      <Box mt={1}>
        <Typography align="center" variant="h5">
          @2021 yachinaka farm
        </Typography>
      </Box>
    </footer>
  )
}
