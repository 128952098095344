import { Box, Grid, Typography } from '@material-ui/core'
import React from 'react'

type ChunkProps = {
  title: string
  desc: string
}

const Chunck = (props: ChunkProps) => {
  return (
    <Box mt={4}>
      <Typography variant="h4">{props.title}</Typography>
      <Box mt={2} />
      <Typography variant="h5">{props.desc}</Typography>
    </Box>
  )
}

export const Privacy = () => {
  return (
    <Box pt={20} bgcolor="#F8F8F8">
      <Grid container>
        <Grid item xs={1} md={2} />
        <Grid item xs={10} md={8}>
          <Typography variant="h1">プライバシーポリシー</Typography>
          <Box mt={8} bgcolor="white" p={5} px={5}>
            <Typography variant="h4">
              当サイトは、お客様の個人情報保護の重要性について認識し、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）を遵守すると共に、以下のプライバシーポリシー（以下「本プライバシーポリシー」といいます。）に従い、適切な取扱い及び保護に努めます。
            </Typography>
            <Chunck
              title="1. 個人情報の定義"
              desc="本プライバシーポリシーにおいて、個人情報とは、個人情報保護法第2条第1項により定義された個人情報、すなわち、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別することができるもの（他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものを含みます。）、もしくは個人識別符号が含まれる情報を意味するものとします。"
            />
            <Chunck
              title="2. 個人情報の利用目的"
              desc="当サイトは、お客様の個人情報を、以下の目的で利用致します。 　（１） 当サイトサービスの提供のため 　（２） 当サイトサービスに関するご案内、お問い合わせ等への対応のため 　（３） 当サイトの商品、サービス等のご案内のため 　（４） 当サイトサービスに関する当サイトの規約、ポリシー等（以下「規約等」といいます。）に違反する行為に対する対応のため 　（５） 当サイトサービスに関する規約等の変更などを通知するため 　（６） 当サイトサービスの改善、新サービスの開発等に役立てるため 　（７） 当サイトサービスに関連して、個別を識別できない形式に加工した統計データを作成するため 　（８） その他、上記利用目的に付随する目的のため 当サイトは、個人情報の利用目的を、関連性を有すると合理的に認められる範囲内において変更することがあり、変更した場合にはお客様に対し、通知又は当社サイト上での掲示その他分かりやすい方法により公表します。"
            />
            <Chunck
              title="2. 個人情報の利用目的"
              desc="当サイトは、お客様の個人情報を、以下の目的で利用致します。 　（１） 当サイトサービスの提供のため 　（２） 当サイトサービスに関するご案内、お問い合わせ等への対応のため 　（３） 当サイトの商品、サービス等のご案内のため 　（４） 当サイトサービスに関する当サイトの規約、ポリシー等（以下「規約等」といいます。）に違反する行為に対する対応のため 　（５） 当サイトサービスに関する規約等の変更などを通知するため 　（６） 当サイトサービスの改善、新サービスの開発等に役立てるため 　（７） 当サイトサービスに関連して、個別を識別できない形式に加工した統計データを作成するため 　（８） その他、上記利用目的に付随する目的のため 当サイトは、個人情報の利用目的を、関連性を有すると合理的に認められる範囲内において変更することがあり、変更した場合にはお客様に対し、通知又は当社サイト上での掲示その他分かりやすい方法により公表します。"
            />
            <Chunck
              title="2. 個人情報の利用目的"
              desc="当サイトは、お客様の個人情報を、以下の目的で利用致します。 　（１） 当サイトサービスの提供のため 　（２） 当サイトサービスに関するご案内、お問い合わせ等への対応のため 　（３） 当サイトの商品、サービス等のご案内のため 　（４） 当サイトサービスに関する当サイトの規約、ポリシー等（以下「規約等」といいます。）に違反する行為に対する対応のため 　（５） 当サイトサービスに関する規約等の変更などを通知するため 　（６） 当サイトサービスの改善、新サービスの開発等に役立てるため 　（７） 当サイトサービスに関連して、個別を識別できない形式に加工した統計データを作成するため 　（８） その他、上記利用目的に付随する目的のため 当サイトは、個人情報の利用目的を、関連性を有すると合理的に認められる範囲内において変更することがあり、変更した場合にはお客様に対し、通知又は当社サイト上での掲示その他分かりやすい方法により公表します。"
            />
            <Chunck
              title="2. 個人情報の利用目的"
              desc="当サイトは、お客様の個人情報を、以下の目的で利用致します。 　（１） 当サイトサービスの提供のため 　（２） 当サイトサービスに関するご案内、お問い合わせ等への対応のため 　（３） 当サイトの商品、サービス等のご案内のため 　（４） 当サイトサービスに関する当サイトの規約、ポリシー等（以下「規約等」といいます。）に違反する行為に対する対応のため 　（５） 当サイトサービスに関する規約等の変更などを通知するため 　（６） 当サイトサービスの改善、新サービスの開発等に役立てるため 　（７） 当サイトサービスに関連して、個別を識別できない形式に加工した統計データを作成するため 　（８） その他、上記利用目的に付随する目的のため 当サイトは、個人情報の利用目的を、関連性を有すると合理的に認められる範囲内において変更することがあり、変更した場合にはお客様に対し、通知又は当社サイト上での掲示その他分かりやすい方法により公表します。"
            />
            <Chunck
              title="2. 個人情報の利用目的"
              desc="当サイトは、お客様の個人情報を、以下の目的で利用致します。 　（１） 当サイトサービスの提供のため 　（２） 当サイトサービスに関するご案内、お問い合わせ等への対応のため 　（３） 当サイトの商品、サービス等のご案内のため 　（４） 当サイトサービスに関する当サイトの規約、ポリシー等（以下「規約等」といいます。）に違反する行為に対する対応のため 　（５） 当サイトサービスに関する規約等の変更などを通知するため 　（６） 当サイトサービスの改善、新サービスの開発等に役立てるため 　（７） 当サイトサービスに関連して、個別を識別できない形式に加工した統計データを作成するため 　（８） その他、上記利用目的に付随する目的のため 当サイトは、個人情報の利用目的を、関連性を有すると合理的に認められる範囲内において変更することがあり、変更した場合にはお客様に対し、通知又は当社サイト上での掲示その他分かりやすい方法により公表します。"
            />
            <Chunck
              title="2. 個人情報の利用目的"
              desc="当サイトは、お客様の個人情報を、以下の目的で利用致します。 　（１） 当サイトサービスの提供のため 　（２） 当サイトサービスに関するご案内、お問い合わせ等への対応のため 　（３） 当サイトの商品、サービス等のご案内のため 　（４） 当サイトサービスに関する当サイトの規約、ポリシー等（以下「規約等」といいます。）に違反する行為に対する対応のため 　（５） 当サイトサービスに関する規約等の変更などを通知するため 　（６） 当サイトサービスの改善、新サービスの開発等に役立てるため 　（７） 当サイトサービスに関連して、個別を識別できない形式に加工した統計データを作成するため 　（８） その他、上記利用目的に付随する目的のため 当サイトは、個人情報の利用目的を、関連性を有すると合理的に認められる範囲内において変更することがあり、変更した場合にはお客様に対し、通知又は当社サイト上での掲示その他分かりやすい方法により公表します。"
            />
            <Chunck
              title="2. 個人情報の利用目的"
              desc="当サイトは、お客様の個人情報を、以下の目的で利用致します。 　（１） 当サイトサービスの提供のため 　（２） 当サイトサービスに関するご案内、お問い合わせ等への対応のため 　（３） 当サイトの商品、サービス等のご案内のため 　（４） 当サイトサービスに関する当サイトの規約、ポリシー等（以下「規約等」といいます。）に違反する行為に対する対応のため 　（５） 当サイトサービスに関する規約等の変更などを通知するため 　（６） 当サイトサービスの改善、新サービスの開発等に役立てるため 　（７） 当サイトサービスに関連して、個別を識別できない形式に加工した統計データを作成するため 　（８） その他、上記利用目的に付随する目的のため 当サイトは、個人情報の利用目的を、関連性を有すると合理的に認められる範囲内において変更することがあり、変更した場合にはお客様に対し、通知又は当社サイト上での掲示その他分かりやすい方法により公表します。"
            />
          </Box>
        </Grid>
        <Grid item xs={1} md={2} />
      </Grid>
    </Box>
  )
}
