import {
  Box,
  Button,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Product } from 'ts/entity/product'
import './Home.css'
import '../App.css'
import ScrollAnimation from 'react-animate-on-scroll'
import { localImage } from '../utils/utils'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    relative_box: {
      position: 'relative',
    },
    header_logo: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    header_image: {
      width: '100%',
      objectFit: 'cover',
    },
    links_item: {
      textDecoration: 'none',
      color: '#333333',
    },
    // スマホ端末サイズ
    [theme.breakpoints.down('xs')]: {
      header_image: {
        height: '100vh',
      },
      header_logo: {
        width: '50vw',
        header: '50vw',
      },
    },
    // タブレットサイズ
    [theme.breakpoints.up('sm')]: {
      header_image: {
        height: '100vh',
      },
      header_logo: {
        width: '40vw',
        header: '40vw',
      },
    },
    // PCサイズ以上
    [theme.breakpoints.up('md')]: {
      header_image: {
        height: '70vh',
      },
      header_logo: {
        width: '20vw',
        header: '20vw',
      },
    },
  }),
)

/**
 * /home
 * ページルートcomponents
 */
const Home = () => {
  return (
    <div>
      <HeaderImage />
      <Box mt={10} />
      <About />
      <Box mt={10} />
      <Description />
      <Box mt={10} />
      <Products />
      <Box mt={10} />
      <HomeFooter />
    </div>
  )
}

/**
 * ホームヘッダ
 */
const HeaderImage = () => {
  const classes = useStyles()
  return (
    <ScrollAnimation animateIn="fadeIn" animateOnce>
      <Box className={classes.relative_box}>
        {/* 背景画像 */}
        <img
          className={classes.header_image}
          src={localImage('header.jpg')}
          alt="header"
        />
        {/* ロゴイメージ */}
        <img
          className={classes.header_logo}
          src={localImage('logp02.svg')}
          alt="header_logo"
        />
      </Box>
    </ScrollAnimation>
  )
}

/**
 * やちなかファームの紹介
 */
const About = () => {
  return (
    <ScrollAnimation animateIn="fadeIn" animateOnce>
      <Box mt={4} />
      <Grid container alignItems="center" justify="center">
        <Grid item xs={12} md={5}>
          <Box padding={4}>
            <Typography variant="h1">やちなかファームの紹介</Typography>
            <Box mt={4} />
            <Typography variant="h4">
              本州最北の地、青森県。
              <br />
              <b>世界遺産白神山地</b>から流れる<b>岩木山の良質な水</b>
              を使った、
              <b>水耕栽培によるミニトマト専門ファーム</b>です。
              <br />
              昼間は太陽がたっぷり降り注ぎ、夜は日本海からの海風で涼しい津軽平野の寒暖差を生かし、美味しいミニトマトづくりを目指しています。
              <br />
              「やちなか」とは、青森県つがる木造町内の旧地名。今でも親しまれる名に、地域活性の願いを込めました。
            </Typography>
            <Box mt={4}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <img
                    src={localImage('explanation01.jpg')}
                    alt="explanation01"
                    style={{ borderRadius: '10px' }}
                    width="100%"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <img
                    src={localImage('explanation02.jpg')}
                    alt="explanation02"
                    style={{ borderRadius: '10px' }}
                    width="100%"
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={5}>
          <Box padding={4}>
            <img
              src={localImage('explanation03.jpg')}
              alt="explanation03"
              width="100%"
            />
          </Box>
        </Grid>
      </Grid>
    </ScrollAnimation>
  )
}

/**
 * 水耕栽培について
 */
const Description = () => {
  const classes = useStyles()
  const theme = useTheme()
  const lessSmSize = useMediaQuery(theme.breakpoints.down('sm'))
  var widthSize: string = '50vw'
  if (lessSmSize) {
    widthSize = '100vw'
  }
  return (
    <ScrollAnimation animateIn="fadeIn" animateOnce>
      <Box className={classes.relative_box} height="40vh">
        <img
          className="Desc-Img"
          src={localImage('hydroponic.jpg')}
          alt="hydroponic"
        />
        <Grid
          className="Desc-Contents"
          container
          justify="center"
          alignItems="center"
          direction="row"
        >
          <Grid item xs={12} md={6}>
            <Box width={widthSize} className="Desc-Contents-Explain" />
            <Box
              className="Desc-Contents-Text"
              height="40vh"
              width={widthSize}
              alignItems="left"
              justifyContent="center"
              display="flex"
              flexDirection="column"
            >
              <Box px={5}>
                <Typography variant="h2">こだわりの水耕栽培</Typography>
              </Box>
              <Box mt={4} px={5}>
                <Typography variant="h4">
                  水の中に根を張らせて成長させる栽培方法です。
                  土耕栽培に比べて成長スピードが速く、安定した育成環境のもと病気や害虫からも守りやすいのが特徴です。
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box height="40vh" />
          </Grid>
        </Grid>
      </Box>
    </ScrollAnimation>
  )
}

/**
 * 商品一覧
 */
const Products = () => {
  return (
    <div>
      <RawProducts />
      <Box mt={10} />
      <OtherProducts />
      <Box mt={10} />
      <ProductsFooter />
    </div>
  )
}

/**
 * 未加工商品一覧
 */
const RawProducts = () => {
  const classes = useStyles()
  return (
    <ScrollAnimation animateIn="fadeIn" animateOnce>
      <Grid container justify="center">
        <Grid item xs={10} md={6}>
          <Typography variant="h1">商品紹介</Typography>
          <Box mt={6} />
          <Typography variant="h2">農学博士のDr.トマト</Typography>
          <Box mt={4} />
          <Typography variant="h4">
            農学博士監修の小まめな栽培管理のもと、水耕栽培で生産しています。寒暖差による適度なストレスにより自らの身を守ろうと養分をため込むため、果肉が締まり、深い甘みがあるミニトマトに仕上がります。
            <br />
            今にも弾けそうな艶のある実は水耕栽培ならでは。
            ピンと張った、生き生きしたヘタは採れたての証です。収穫当日に産地直送でお届けいたします。
          </Typography>
          <Box mt={4} />
          <Grid container spacing={4} justify="center" alignContent="center">
            <Grid item xs={10} md={5}>
              <a
                href="https://yachinaka.theshop.jp/items/31135272"
                className={classes.links_item}
              >
                <img
                  className="Products-Image"
                  style={{ borderRadius: '10px' }}
                  src="https://base-ec2if.akamaized.net/w=640,a=0,q=90,u=1/images/item/origin/c990e9b8519bf5d119ad4c38aee26f8a.jpg"
                  alt="logo"
                />
                <Box mt={2} />
                <Typography
                  variant="h3"
                  style={{ fontSize: '20px' }}
                  align="center"
                >
                  農学博士のDr.トマト
                </Typography>
              </a>
            </Grid>
            <Grid item xs={10} md={5}>
              <a
                href="https://yachinaka.theshop.jp/items/34767678"
                className={classes.links_item}
              >
                <img
                  className="Products-Image"
                  style={{ borderRadius: '10px' }}
                  src="https://base-ec2if.akamaized.net/w=640,a=0,q=90,u=1/images/item/origin/a24448bda2781cf14877827c2439721d.jpg"
                  alt="logo"
                />
                <Box mt={2} />
                <Typography
                  variant="h3"
                  style={{ fontSize: '20px' }}
                  align="center"
                >
                  Dr.トマトカロテンプラス＋
                </Typography>
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ScrollAnimation>
  )
}

const OtherProducts = () => {
  return (
    <Grid container justify="center">
      <Grid item xs={10} md={6}>
        <ScrollAnimation animateIn="fadeIn" animateOnce>
          <Typography variant="h2">無添加ドライトマト</Typography>
          <Box mt={4} />
          <Typography variant="h4">
            当ファームで採れたDr.トマトを使用した、皮ありと皮なしの2種類ドライトマトです。砂糖や塩、オイルなども使用せず、素材の味をそのままに身体に優しく仕上げました。
            <br />
            ミニトマトは乾燥させることにより旨味成分や栄養素が凝縮するため、栄養補給にもお役立ていただけます。生のミニトマトとは違った濃厚な味わいをお楽しみください。
          </Typography>
        </ScrollAnimation>
        <Box mt={4} />
        <OtherProduct
          id={0}
          title={'リコベリー（皮無し）'}
          desc={`一粒一粒湯むきし、長時間じっくり低温乾燥させました。 
皮ありに比べて甘味が強く、弾力のある食感とベリーの様な味と香りが特徴です。`}
          imageUrl={
            'https://base-ec2if.akamaized.net/w=640,a=0,q=90,u=1/images/item/origin/5153aa763ae3ccb2f8b3eba7f98a3840.jpg'
          }
          link={'https://yachinaka.theshop.jp/items/32062599'}
        />

        <Box mt={4} />

        <OtherProduct
          id={0}
          title={'ラ・トマ　（皮あり）'}
          desc={`皮ごとカットして、長時間じっくり低温乾燥させました。 
お料理などに入れると皮の酸味もアクセントになり、美味しくお召し上がりいただけます。`}
          imageUrl={
            'https://base-ec2if.akamaized.net/w=640,a=0,q=90,u=1/images/item/origin/eb1dd3c28bfb4ec8c98a70df7d116567.jpg'
          }
          link={'https://yachinaka.theshop.jp/items/32062344'}
        />
      </Grid>
    </Grid>
  )
}

const OtherProduct = (product: Product) => {
  const classes = useStyles()
  return (
    <ScrollAnimation animateIn="fadeIn" animateOnce>
      <a href={product.link} className={classes.links_item}>
        <Grid container spacing={2} justify="center">
          <Grid item xs={10} md={4}>
            <img
              className="Products-Image"
              src={product.imageUrl}
              alt="logo"
              style={{ borderRadius: '10px' }}
            />
          </Grid>
          <Grid item xs={10} md={8}>
            <Typography variant="h3" style={{ fontSize: '20px' }}>
              {product.title}
            </Typography>
            <Box mt={2} />
            <Typography variant="h4" className="Wrap-Space-Text">
              {product.desc}
            </Typography>
          </Grid>
        </Grid>
      </a>
    </ScrollAnimation>
  )
}

const ProductsFooter = () => {
  return (
    <div>
      <Grid container justify="center">
        <Grid item xs={10} md={6}>
          <Typography variant="h4" align="center">
            昼夜の寒暖差が大きい津軽平野の恵みを受け果肉が締まり、深い甘味のある味わいに育ちました。
            <br />
            皆様にご購入いただいた後に収穫し新鮮でツヤツヤのミニトマトをその日のうちに農家直送で発送致します。
            <br />
            6月下旬〜10月頃までの期間限定出品となりますので、是非一度ご賞味ください。
          </Typography>
          <Box mt={4} />
        </Grid>
      </Grid>
      <Grid container justify="center">
        <Grid item xs={10} md={4}>
          <Button
            variant="contained"
            color="primary"
            className={'Primary-Yachinaka-Button'}
            href="https://yachinaka.theshop.jp/"
          >
            <Typography variant="h3">購入はこちら</Typography>
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

const HomeFooter = () => {
  const classes = useStyles()
  return (
    <ScrollAnimation animateIn="fadeIn" animateOnce>
      <Box className={classes.relative_box}>
        <img
          className="Footer-Img"
          src={localImage('contact_bg.jpg')}
          alt="logo"
        />
        <div className="Footer-Back" />
        <Grid className="Footer-Text">
          <Grid item xs={10} md={4}>
            <Box fontWeight="fontWeightBold">
              <Typography variant="h4" align="center">
                取材や業務用として仕入れたい、 <br />
                インターンについてなど
                <br />
                購入以外に関することはこちらからお願いします。
              </Typography>
              <Box mt={2} />
              <Button
                variant="contained"
                color="primary"
                className={'Primary-Yachinaka-Button'}
                href="mailto:yachinakafarm@gmail.com?subject=お問い合わせ"
              >
                <Typography variant="h3">お問い合わせ</Typography>
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </ScrollAnimation>
  )
}

export default Home
